import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { title: '登录' }
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('../views/home.vue'),
  //   meta: { title: '首页' }
  // },
  {
    path: '/store_data',
    name: 'store_data',
    component: () => import('../views/pages/store_data.vue'),
    meta: { title: '门店' }
  },
  {
    path: '/enterprises_data',
    name: 'enterprises_data',
    component: () => import('../views/pages/enterprises_data.vue'),
    meta: { title: '药企' }
  },
  {
    path: '/store_data_ykf',
    name: 'store_data_ykf',
    component: () => import('../views/pages/store_data_ykf.vue'),
    meta: { title: '门店' }
  },
  {
    path: '/enterprises_data_ykf',
    name: 'enterprises_data_ykf',
    component: () => import('../views/pages/enterprises_data_ykf.vue'),
    meta: { title: '药企' }
  },
  {
    path: '/person_info',
    name: 'person_info',
    component: () => import('../views/pages/person_info.vue'),
    meta: { title: '个人信息' }
  },
  {
    path: '/change_psw',
    name: 'change_psw',
    component: () => import('../views/pages/change_psw.vue'),
    meta: { title: '修改密码' }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: { title: '首页' }
  },
  // {
  //   path: '/mine',
  //   name: 'mine',
  //   component: () => import('../views/mine.vue'),
  //   meta: { title: '首页' }
  // }
]
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
