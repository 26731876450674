<template>
  <div id="app">
    <router-view />
    <!-- <tabbar v-if="$route.meta.showFooter" :isShow="isShow" /> -->
  </div>
</template>
<script>
import bus from './utils/bus';
// import tabbar from './views/main';
export default {
  name: "App",
  components: {
    // tabbar
  },
  data() {
    return {
      // isShow: false,
      socket: null
    }
  },
  created() {
    // this.initSocket();
    bus.$on('onBus', (data) => {
      console.log('调用onBus', data);
      this.isShow = data;
    })
    console.log('this===>', this.$route)
  },
  methods: {
    initSocket() {
      let userData = JSON.parse(localStorage.getItem('userData'));
      console.log(`ws://192.168.0.104:9090/server/websocket/userId=${userData.memberId}&userType=1`)
      this.socket = new WebSocket(`ws://192.168.0.104:9090/server/websocket?userId=${userData.memberId}&userType=1`);
      this.socket.onopen = function (e) {
        console.log('链接成功', e)

      }
      this.socket.onclose = function (e) {
        console.log('连接成败');
      }
      this.socket.onmessage = (e) => {
        console.log('接受', e);
        if (e.data === 'websocket连接成功') {
          this.isShow = false;
        } else {
          let queryData = JSON.parse(e.data);
          if (queryData.sessionType == 2) {
            this.isShow = true;
            queryData.sessionType = 1;
            console.log(queryData)
            this.socket.send(JSON.stringify(queryData))
            // this.list();
          } else {
            this.isShow = false;
          }

        }
        console.log('isShow', this.isShow);
      }
    },
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}

body {
  height: 100%;
  // overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
</style>
