import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    message: '',
    arrImg: null,
    relation: '',
    interrogationId: null, //问诊人id
    visitsType: null,
    fastPrice: 5, //快速问诊价格
    floatRate: 0, //定向问诊价格浮动比例
    docDetail: {}, //医生详情
    docType: 2, //医生类型 2为春雨 1为药康夫
    priceInfo: {}, //电话时长价格
    orderIdArr: [], //未读消息订单id集合
    devPrice: 0,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
